import React, { useEffect, useRef, useState } from "react";
import { useStateContext } from "../../context/ContextProvider";
import Children from "../../layout/Children";
import { useNavigate, useParams } from "react-router-dom";
import { Footer, Header } from "../../components";
import axios from "axios";
import { useDownloadExcel } from "react-export-table-to-excel";
import {
	BsFileEarmarkPdf,
	BsPlusSquareFill,
	BsTrash,
	BsPencilFill,
} from "react-icons/bs";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegEye } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";

const Bar = () => {
	const params = useParams();
	const [openEdit, setOpendEdit] = useState(false);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const [tujuanPerusahaan, setTujuanPerusahaan] = useState("");
	const [openModal, setOpenModal] = useState(false);
	const [tahapKerka, setTahapKerja] = useState(false);
	const [data, setData] = useState([]);
	const { currentColor, roles, token, currentMode } = useStateContext();
	const [open, setOpen] = useState(false);
	const [idEdit, setIdEdit] = useState(null);
	const navigate = useNavigate();
	const [keterangan, setKeterangan] = useState([
		{ keterangan: "Umum", id: "" },
		{ keterangan: "Desa Terdampak", id: "" },
	]);
	const [allPosisi, setAllPosisi] = useState([]);
	const [namaPosisi, setNamaPosisi] = useState("");
	const [statusPosisi, setStatusPosisi] = useState("");
	const [total, setTotal] = useState();
	const [edit, setEdit] = useState({
		status_test: "Belum Test",
		alasan_test: "",
		posisi: "",
		keterangan: "",
	});
	const [namaPelamar, setNamaPelamar] = useState("");

	const getData = async () => {
		setLoading(true);
		if (params.id === 1 && tahapKerka === true) {
			const response = await axios.get(
				`https://api.ctk-kiba.com/api/posisi/tahap2`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setTotal(response.data.pelamar.length);
			setData(response.data.pelamar);
			setNamaPosisi(response.data.nama_posisi);
			setLoading(false);
		} else if (params.id === 1 && tahapKerka === false) {
			const response = await axios.get(
				`https://api.ctk-kiba.com/api/posisi/tahap1`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setNamaPosisi(response.data.nama_posisi);
			setTotal(response.data.pelamar.length);
			setData(response.data.pelamar);
			setLoading(false);
		} else {
			const response = await axios.get(
				`https://api.ctk-kiba.com/api/posisi/${params.id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setNamaPosisi(response.data.nama_posisi);
			setTotal(response.data.pelamar.length);
			setData(response.data.pelamar);
			setLoading(false);
		}
	};

	const deletePosisi = async () => {
		try {
			await axios.delete(`https://api.ctk-kiba.com/api/posisi/${params.id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			navigate("/semuaposisi");
		} catch (error) {
			console.log(error);
		}
	};

	const deleteUser = async (id) => {
		try {
			await axios.delete(`https://api.ctk-kiba.com/api/pelamar/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			getData();
		} catch (error) {
			console.log(error);
		}
	};
	const editPosisi = async () => {
		try {
			await axios.patch(
				`https://api.ctk-kiba.com/api/posisi/${params.id}`,
				{
					status_posisi: statusPosisi,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			navigate("/semuaposisi");
		} catch (error) {
			console.log(error);
		}
	};
	const getPosisi = async () => {
		const response = await axios.get(
			"https://api.ctk-kiba.com/api/posisi/public",
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		setAllPosisi(response.data);
	};

	const tableRef = useRef(null);
	const { onDownload } = useDownloadExcel({
		currentTableRef: tableRef.current,
		filename: `Data Pelamar ${namaPosisi}`,
		sheet: `${namaPosisi}`,
	});

	const findOine = async () => {
		const res = await axios.get(
			`https://api.ctk-kiba.com/api/pelamar/${idEdit}`
		);
		setEdit({
			alasan_test: res.data.alasan_test,
			status_test: res.data.status_test,
			posisi: res.data.posisi.id,
			keterangan: res.data.keterangan,
		});
	};

	const change = (e) => {
		const fieldName = e.target.name;
		setEdit((exist) => ({
			...exist,
			[fieldName]: e.target.value,
		}));
	};

	const editPelamar = async () => {
		try {
			const edi = await axios.patch(
				`https://api.ctk-kiba.com/api/pelamar/${idEdit}`,
				{
					posisi: edit.posisi,
					status_test: edit.status_test,
					alasan_test: edit.alasan_test,
					keterangan: edit.keterangan,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (edi.status === 200) {
				setOpen(false);
				setEdit({ alasan_test: "", status_test: "" });
				getData();
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getData();
		getPosisi();
	}, [tahapKerka, search, namaPelamar]);

	useEffect(() => {
		if (!token) {
			navigate("/");
		}
	}, [edit]);

	return (
		<Children>
			<div className=" md:m-10 min-h-screen p-5 bg-white rounded-xl dark:bg-secondary-dark-bg">
				<Header title={`${namaPosisi}`} category="Data" />
				{openModal && (
					<>
						<div className="justify-center text-left items-center md:p-0 lg:p-0 p-5 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
							<div className="relative w-auto my-6 mx-auto max-w-3xl">
								{/*content*/}
								<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
									{/*header*/}
									<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
										<h3 className="text-3xl text-green-400 text-center font-semibold">
											PT Bantaeng Sinergi Cemerlang
										</h3>
										<button
											className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
											onClick={() => setOpen(false)}>
											<span className=" bg-red-400 text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
												×
											</span>
										</button>
									</div>
									{/*body*/}
									<div className="relative p-6 flex-auto">
										<div className="flex flex-wrap -mx-3 mb-6">
											<div className="w-full px-3">
												<label
													className={`block uppercase tracking-wide text-xs font-bold text-gray-700 mb-2`}
													htmlFor="status_test">
													Perusahaan Yang Akan Dituju
												</label>
												<input
													className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
													id=""
													type="text"
													name="perusahaan"
													onChange={(e) => setTujuanPerusahaan(e.target.value)}
													value={tujuanPerusahaan}
													placeholder="Contoh PT-Huady"
												/>
											</div>
										</div>
									</div>
									{/*footer*/}
									<div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
										<button
											className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
											type="button"
											onClick={() => setOpenModal(false)}>
											Close
										</button>
										<button
											className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
											type="button"
											onClick={onDownload}>
											Export Excel
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
					</>
				)}
				<div className="sm:flex md:mb-4 lg:mb-4 sm:justify-between sm:items-center ">
					<div className="items-center w-1/3 flex justify-center ">
						<div className="w-full">
							<div className="relative">
								<label
									className={`block uppercase tracking-wide ${
										currentMode === "Dark" ? "text-white" : "text-gray-700"
									} text-xs font-bold mb-2`}
									htmlFor="jenis_kelamin">
									Filter Berdasarkan
								</label>
								<select
									required
									onChange={(e) => setSearch(e.target.value)}
									value={search}
									name="jenis_kelamin"
									className="block uppercase appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
									id="jenis_kelamin">
									<option value="">Keseluruhan</option>
									{keterangan.map((item, index) => (
										<option key={item.keterangan} value={item.keterangan}>
											{item.keterangan}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
					<div className="flex gap-8 my-6 md:w-1/2 md:mt-0 md:mb-0 text-center justify-end items-center">
						{params.id == "1" ? (
							<>
								<div
									onClick={() => setTahapKerja(false)}
									style={{ backgroundColor: currentColor }}
									className="flex gap-3 items-center justify-center w-1/3 cursor-pointer text-white bg-blue-600 whitespace-nowrap hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
									<BsPlusSquareFill />
									Tahap 1
								</div>
								<div
									onClick={() => setTahapKerja(true)}
									style={{ backgroundColor: currentColor }}
									className="flex gap-3 items-center justify-center w-1/3 cursor-pointer text-white whitespace-nowrap bg-blue-600 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
									<BsPlusSquareFill />
									Tahap 2
								</div>
							</>
						) : (
							""
						)}
						<div
							onClick={() => setOpendEdit(true)}
							className="flex gap-3 items-center hover:cursor-pointer justify-center w-1/3 text-white bg-yellow-600 hover:bg-yellow-500 focus:ring-4 focus:outline-nonefont-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-blue-800">
							<BsPencilFill /> Edit Posisi
						</div>
						<div
							onClick={() => setOpenModal(true)}
							className="flex gap-3 items-center hover:cursor-pointer justify-center w-1/3 whitespace-nowrap text-white bg-green-600 hover:bg-green-500 focus:ring-4 focus:outline-nonefont-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-blue-800">
							<RiFileExcel2Line /> Export Excel
						</div>
						<div
							onClick={() => {
								if (
									window.confirm(
										"Pastikan Pelamar Kosong Pada Posisi Ini Sebelum Menghapus"
									)
								)
									deletePosisi(params.id);
							}}
							className={`flex gap-3 ${
								roles !== "admin" ? "hidden" : ""
							} items-center hover:cursor-pointer justify-center w-1/3 whitespace-nowrap text-white bg-red-600 hover:bg-red-500 focus:ring-4 focus:outline-nonefont-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-blue-800`}>
							<BsTrash /> Hapus Posisi
						</div>
					</div>
				</div>
				<div className="sm:flex md:mb-4 lg:mb-4 sm:justify-end sm:items-center ">
					<div className="items-center w-1/3 flex justify-center ">
						<div className="w-full">
							<div className="relative">
								<input
									type="text"
									placeholder="Cari Nama"
									className="w-full h-10 px-5 text-sm text-gray-900 bg-white border rounded-lg focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
									onChange={(e) => setNamaPelamar(e.target.value)}
									value={namaPelamar}
								/>
							</div>
						</div>
					</div>
				</div>
				{loading ? (
					<div
						role="status"
						className="w-full h-[60%] flex justify-center items-center">
						<svg
							aria-hidden="true"
							className="mr-2 w-52 h-52 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
							viewBox="0 0 100 101"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
								fill="currentColor"
							/>
							<path
								d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
								fill="currentFill"
							/>
						</svg>
						<span className="sr-only">Loading...</span>
					</div>
				) : (
					<div className="flex flex-col">
						<div className=" overflow-auto sm:mx-0.5 lg:mx-0">
							<div className="py-2 inline-block min-w-full sm:px-6 lg:px-0 md:px-0">
								<div className="overflow-hidden">
									<table className="min-w-full" ref={tableRef}>
										<thead className="bg-gray-200 border-b">
											<tr>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-left whitespace-nowrap">
													ID
												</th>
												<th
													scope="col"
													className="text-sm hidden font-medium text-center text-gray-900 px-6 py-4 whitespace-nowrap">
													No Test
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-center text-gray-900 px-6 py-4 whitespace-nowrap">
													Nama Lengkap
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-center text-gray-900 px-6 py-4  whitespace-nowrap">
													NIK
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													Posisi
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													No Telpon
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													Jenis Kelamin
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													Tempat Lahir
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													Tanggal Lahir
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-8 py-4 text-center  whitespace-nowrap">
													Provinsi
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-8 py-4 text-center whitespace-nowrap">
													Kabupaten
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													Kecamatan
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													Desa/Kelurahan
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													Dusun
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													Pendidikan Terakhir
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													Pengalaman Kerja
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													Pengalaman Kerja 2
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													Pengalaman Kerja 3
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													Status Test
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													Alasan Test
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													Keterangan
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													Dokumen
												</th>
												<th
													scope="col"
													className="text-sm font-medium text-gray-900 px-6 py-4 text-center whitespace-nowrap">
													Actions
												</th>
											</tr>
										</thead>
										<tbody>
											{data

												.filter(
													(item, index) =>
														item.keterangan.match(new RegExp(search, "i")) &&
														item.nama_lengkap.match(
															new RegExp(namaPelamar, "i")
														)
												)
												.map((item, index) => (
													<tr
														key={item.id}
														className="bg-white uppercase border-b transition duration-300 ease-in-out hover:bg-gray-100">
														<td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
															{index + 1}
														</td>
														<td className="text-sm hidden text-center text-gray-900 font-light px-6 py-4 whitespace-nowrap">
															00{item.id}-{namaPosisi}-
															{item.created_at.slice(0, 10)}-{tujuanPerusahaan}
														</td>
														<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
															{item.nama_lengkap}
														</td>
														<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
															{item.nik}
														</td>
														<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
															{namaPosisi}
														</td>
														<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
															{item.no_telpon}
														</td>
														<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
															{item.jenis_kelamin}
														</td>
														<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
															{item.tempat_lahir}
														</td>
														<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
															{item.tanggal_lahir}
														</td>
														<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
															{item.provinsi}
														</td>
														<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
															{item.kabupaten}
														</td>
														<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
															{item.kecamatan}
														</td>
														<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
															{item.desa}
														</td>
														<td className="text-sm text-gray-900  text-center font-light px-6 py-4 whitespace-nowrap">
															{item.dusun}
														</td>
														<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
															{item.pendidikan_terakhir}
														</td>
														<td className="text-sm whitespace-pre-wrap w text-gray-900 text-center font-light px-6 py-4 w-1/2">
															{item.pengalaman_kerja == ""
																? "Belum Memiliki Pengalaman Kerja"
																: item.pengalaman_kerja}
														</td>
														<td className="text-sm whitespace-pre-wrap w text-gray-900 text-center font-light px-6 py-4 w-1/2">
															{item.pengalaman_kerja2 == ""
																? "Belum Memiliki Pengalaman Kerja 2"
																: item.pengalaman_kerja2}
														</td>
														<td className="text-sm whitespace-pre-wrap w text-gray-900 text-center font-light px-6 py-4 w-1/2">
															{item.pengalaman_kerja3 == ""
																? "Belum Memiliki Pengalaman Kerja 3"
																: item.pengalaman_kerja3}
														</td>
														<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
															{item.status_test}
														</td>
														<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
															{item.alasan_test}
														</td>
														<td className="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap">
															{item.keterangan}
														</td>
														<td className="text-sm text-center font-light px-6 py-4 whitespace-nowrap">
															<div className="flex justify-center items-center gap-5">
																<div>
																	<a
																		href={`https://api.ctk-kiba.com/api/pelamar/buffer/${item.dokumen}`}
																		className="text-red-500">
																		{item.dokumen}
																	</a>
																	<BsFileEarmarkPdf color="red" />
																</div>
																<div
																	className="cursor-pointer"
																	onClick={() =>
																		navigate("/pdf", {
																			state: {
																				nama_file: item.dokumen,
																			},
																		})
																	}>
																	<FaRegEye color="blue" />
																</div>
															</div>
														</td>
														<td className="text-sm  text-gray-900 text-center font-light px-2 py-4">
															<div className="flex justify-center gap-3 items-center">
																<TooltipComponent
																	content="Hapus"
																	position="TopCenter">
																	<span
																		className="hover:cursor-pointer"
																		onClick={() => {
																			if (
																				window.confirm(
																					"Apakah Anda Yakin Ingin Menghapus Anggota?"
																				)
																			)
																				deleteUser(item.id);
																		}}>
																		<MdOutlineDeleteOutline color="red" />
																	</span>
																</TooltipComponent>
																<TooltipComponent
																	content="Edit"
																	position="TopCenter">
																	<span
																		className="hover:cursor-pointer"
																		onClick={() => {
																			setOpen(true);
																			setIdEdit(item.id);
																			setEdit({
																				alasan_test: item.alasan_test,
																				status_test: item.status_test,
																				posisi: params.id,
																				keterangan: item.keterangan,
																			});
																		}}>
																		<BiEdit color="green" />
																	</span>
																</TooltipComponent>
															</div>
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				)}
				{openEdit && (
					<>
						<div className="justify-center text-left items-center md:p-0 lg:p-0 p-5 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
							<div className="relative w-auto my-6 mx-auto max-w-3xl">
								{/*content*/}
								<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
									{/*header*/}
									<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
										<h3 className="text-3xl text-green-400 text-center font-semibold">
											Edit Status Posisi
										</h3>
										<button
											className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
											onClick={() => setOpen(false)}>
											<span className=" bg-red-400 text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
												×
											</span>
										</button>
									</div>
									{/*body*/}
									<div className="relative p-6 flex-auto">
										<div className="flex flex-wrap -mx-3 mb-6">
											<div className="w-full px-3">
												<label
													className={`block uppercase tracking-wide text-xs font-bold text-gray-700 mb-2`}
													htmlFor="status_test">
													Status Perekrutan
												</label>
												<div className="relative">
													<select
														required
														onChange={(e) => setStatusPosisi(e.target.value)}
														value={statusPosisi}
														name="pendidikan_terakhir"
														className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
														id="pendidikan_terakhir">
														<option>- - - Status Posisi - - -</option>
														<option value="terbuka">Terbuka</option>
														<option value="freeze">
															Freeze/Dalam Proses Perekrutan
														</option>
													</select>
													<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
														<svg
															className="fill-current h-4 w-4"
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 20 20"></svg>
													</div>
												</div>
											</div>
										</div>
									</div>
									{/*footer*/}
									<div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
										<button
											className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
											type="button"
											onClick={() => setOpendEdit(false)}>
											Close
										</button>
										<button
											className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
											type="button"
											onClick={editPosisi}>
											Simpan Data
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>{" "}
					</>
				)}
				{open && (
					<>
						<div className="justify-center text-left items-center md:p-0 lg:p-0 p-5 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
							<div className="relative w-auto my-6 mx-auto max-w-3xl">
								{/*content*/}
								<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
									{/*header*/}
									<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
										<h3 className="text-3xl text-green-400 text-center font-semibold">
											PT Bantaeng Sinergi Cemerlang
										</h3>
										<button
											className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
											onClick={() => setOpen(false)}>
											<span className=" bg-red-400 text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
												×
											</span>
										</button>
									</div>
									{/*body*/}
									<div className="relative p-6 flex-auto">
										<div className="flex flex-wrap -mx-3 mb-6">
											<div className="w-full px-3">
												<label
													className={`block uppercase tracking-wide text-xs font-bold text-gray-700 mb-2`}
													htmlFor="posisi">
													Posisi
												</label>
												<div className="relative">
													<select
														required
														onChange={change}
														value={edit.posisi}
														name="posisi"
														className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
														id="status_test">
														<option>- - - Posisi - - -</option>
														{allPosisi.map((option) => (
															<option key={option.id} value={option.id}>
																{option.nama_posisi}
															</option>
														))}
													</select>
												</div>
											</div>
										</div>
										<div className="flex flex-wrap -mx-3 mb-6">
											<div className="w-full px-3">
												<label
													className={`block uppercase tracking-wide text-xs font-bold text-gray-700 mb-2`}
													htmlFor="status_test">
													Status Test
												</label>
												<div className="relative">
													<select
														onChange={change}
														value={edit.status_test}
														name="status_test"
														className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
														id="status_test">
														<option>- - - Status Test - - -</option>
														<option>Belum Test</option>
														<option>Lulus</option>
														<option>Tidak Lulus</option>
													</select>
												</div>
											</div>
										</div>
										<div className="flex flex-wrap -mx-3 mb-6">
											<div className="w-full px-3">
												<label
													className={`block uppercase tracking-wide text-xs font-bold text-gray-700 mb-2`}
													htmlFor="alasan_test">
													Alasan Kelulusan/Tidak Lulus
												</label>
												<input
													className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
													id="alasan_test"
													type="text"
													onChange={change}
													value={edit.alasan_test}
													name="alasan_test"
													placeholder="Alasan Lulus/Tidak Lulus"
												/>
											</div>
										</div>
									</div>
									{/*footer*/}
									<div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
										<button
											className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
											type="button"
											onClick={() => setOpen(false)}>
											Close
										</button>
										<button
											className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
											type="button"
											onClick={() => editPelamar(idEdit)}>
											Save Changes
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
					</>
				)}
				<Footer />
			</div>
		</Children>
	);
};

export default Bar;
